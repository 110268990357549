/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { U4B_API_HOST_URL } from "constants/envUrls";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function data() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setpage] = useState("get");

  // eslint-disable-next-line no-unused-vars
  const getIPs = async () => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(`${U4B_API_HOST_URL}/BlacklistedIPs/Get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then((data) => {
        if (data.isSuccess) {
          setData(data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteIP = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to remove this Blocked IP?");

    if (isConfirmed) {
      fetch(`${U4B_API_HOST_URL}/BlacklistedIPs/Delete?id=${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.isSuccess) {
            toast.success(data.message);
            getIPs();
          }else{
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  const addIP = async (value) => {
    console.log(value);
    setLoading(true);
    var data = {
      IP: value,
    };

    try {
      const response = await fetch(`${U4B_API_HOST_URL}/BlacklistedIPs/Add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        if (data.isSuccess) {
          setLoading(false);
          setpage("get");
          toast.success(data.message);
          getIPs();
        } else {
          setLoading(false);
          toast.error(data.message);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };
  const editIP = async (value, id) => {
    setLoading(true);
    const data = {
      id: id,
      IP: value,
    };

    try {
      const response = await fetch(`${U4B_API_HOST_URL}/BlacklistedIPs/Edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setLoading(false);
          toast.success(data.message);
          setpage("get");
          getIPs();
        } else {
          setLoading(false);
          toast.warning(data.message);
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      toast.success("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getIPs();
  }, []);

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  return {
    data: data ? data : [],
    loading: loading,
    getIPs: getIPs,
    deleteIP: deleteIP,
    page: page,
    setpage: setpage,
    addIP: addIP,
    editIP: editIP,
  };
}
