/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { U4B_API_HOST_URL } from "constants/envUrls";
import { useEffect, useState } from "react";

export default function data() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const getConnectivityUsers = async () => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(`${U4B_API_HOST_URL}/Connectivity/GetConnectivity`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then((data) => {
        if (data.isSuccess) {
          setData(data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getConnectivityUsers();
  }, []);

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Postal Code", accessor: "postalCode", align: "center" },
      { Header: "IP Address", accessor: "clientIPAddress", align: "center" },
      { Header: "Source", accessor: "reason", align: "center" },
      { Header: "Address", accessor: "address", align: "center" },
    ],

    rows: data
      ? data?.map((user, index) => ({
          name: <Job title={user.name} />,
          email: <Job title={user.email} />,
          phone: <Job title={user.phone} />,
          postalCode: <Job title={user.postalCode} />,
          clientIPAddress: <Job title={user.clientIPAddress} />,
          reason: <Job title={user.reason} />,
          address: <Job title={user.address.length > 100 ? user.address.substring(0, 200) + "..." : user.address} />,
          key: index, // Assign a unique key for each row
        }))
      : [],

    data: data ? data : [],
    loading: loading,
  };
}
