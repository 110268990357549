/* eslint-disable no-bitwise */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/BloackedIPs/data/authorsTableData";
import styles from "./style.module.css";
import { Button, CircularProgress, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";

// import projectsTableData from "layouts/tables/data/projectsTableData";

function BloackedIPs() {
  const [controller] = useMaterialUIController();
  const { data, loading, getIPs, deleteIP, page, setpage, addIP, editIP } = authorsTableData();
  const [newIP, setNewIP] = useState("");
  const [id, setId] = useState(0);
  const IPReg =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  const handleAddIP = () => {
    if (newIP.trim().length > 0 && IPReg.test(newIP.trim())) {
      addIP(newIP);
    } else {
      toast.warning("Please enter a valid IP address");
    }
  };
  const handleEdit = (value) => {
    setpage("edit");
    setNewIP(value.ip);
    setId(value.id);
    console.log(value);
  };
  const handleEditIP = () => {
    if (newIP.trim().length > 0 && IPReg.test(newIP)) {
      editIP(newIP, id);
    } else {
      toast.warning("Please enter a valid IP address");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={controller.sidenavColor}
                borderRadius="lg"
                coloredShadow={controller.sidenavColor}
              >
                <MDTypography variant="h6" color="white">
                  {page === "add"
                    ? "Add New Blocked IP"
                    : page === "edit"
                    ? "Edit Blocked IP"
                    : "Blocked IPs"}
                </MDTypography>
              </MDBox>
              {page === "add" && (
                <>
                  <MDBox pt={5} pb={10} px={5}>
                    <MDBox display="flex" justifyContent="end" alignItems="center" p={1} px={3}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setpage("get");
                          setNewIP("");
                          setId(0);
                        }}
                      >
                        <ArrowBackIcon />
                        Back
                      </MDButton>
                    </MDBox>
                    <div className={styles.form}>
                      <TextField
                        value={newIP}
                        onChange={(e) => setNewIP(e.target.value)}
                        disabled={loading}
                        label="New IP Address"
                        fullWidth
                      />
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={handleAddIP}
                        disabled={loading}
                      >
                        Submit
                      </MDButton>
                    </div>
                  </MDBox>
                </>
              )}
              {page === "edit" && (
                <>
                  <MDBox pt={5} pb={10} px={5}>
                    <MDBox display="flex" justifyContent="end" alignItems="center" p={1} px={3}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => {
                          setpage("get");
                          setNewIP("");
                          setId(0);
                        }}
                      >
                        <ArrowBackIcon />
                        Back
                      </MDButton>
                    </MDBox>
                    <div className={styles.form}>
                      <TextField
                        value={newIP}
                        onChange={(e) => setNewIP(e.target.value)}
                        label="New IP Address"
                        fullWidth
                        disabled={loading}
                      />
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={handleEditIP}
                        disabled={loading}
                      >
                        Edit
                      </MDButton>
                    </div>
                  </MDBox>
                </>
              )}
              {page === "get" && (
                <>
                  <MDBox display="flex" justifyContent="end" alignItems="center" p={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setpage("add");
                        setNewIP("");
                      }}
                    >
                      <AddIcon />
                      Add New
                    </MDButton>
                  </MDBox>
                  <MDBox pt={3}>
                    {loading ? (
                      <MDBox display="flex" justifyContent="center" alignItems="center" py={5}>
                        <CircularProgress color="secondary" />
                      </MDBox>
                    ) : data.length === 0 ? (
                      <MDBox display="flex" justifyContent="center" alignItems="center" py={5}>
                        <MDTypography variant="body1">No Blocked IPs</MDTypography>
                      </MDBox>
                    ) : (
                      <div className={styles.cards}>
                        {data.map((ip, index) => (
                          <Card key={index} className={styles.card}>
                            <MDBox p={2}>
                              <div className={styles.cardInner}>
                                <MDTypography variant="h4">{ip.ip}</MDTypography>
                                <MDBox display="flex" justifyContent="space-between">
                                  <button
                                    className={styles.blogCardButtonEdit}
                                    onClick={() => handleEdit(ip)}
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    className={styles.blogCardButtonDelete}
                                    onClick={() => deleteIP(ip.id)}
                                  >
                                    <DeleteIcon />
                                  </button>
                                </MDBox>
                              </div>
                            </MDBox>
                          </Card>
                        ))}
                      </div>
                    )}
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BloackedIPs;
