import { useMaterialUIController } from "context";
import Styles from "./styles.module.css";
import { U4B_API_HOST_URL } from "constants/envUrls";
import { U4B_IMAGE_HOST_URL } from "constants/envUrls";
import { useState } from "react";
import Document from "../../../../assets/images/document.svg";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function BlogCard({ data, title, author, date, image, handleEdit, handleDelete }) {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className={Styles.blogCard}>
      <div className={Styles.blogCardImageContainer}>
        <div className={Styles.blogCardButtons}>
          <button className={Styles.blogCardButtonEdit} onClick={() => handleEdit(data)}>
            <EditIcon />
          </button>
          <button className={Styles.blogCardButtonDelete} onClick={() => handleDelete(data.id)}>
            <DeleteIcon />
          </button>
        </div>
        {!imgLoaded && (
          <div className={Styles.placeholderDiv}>
            <img src={Document} alt="" className={Styles.placeholder} />
          </div>
        )}
        <div style={{
            position: "relative",
            width: "300px",
            height: "250px",
        }}> 
          <img
            src={`${U4B_IMAGE_HOST_URL}/${image}`}
            alt={title}
            className={Styles.blogCardImage}
            onLoad={() => setImgLoaded(true)}
          />
        </div>
      </div>
      <div className={Styles.blogCardContent}>
        <h3>{title?.length > 20 ? title.substring(0, 20) + "..." : title}</h3>
        <p>{new Date(date).toDateString()}</p>
        <div className={Styles.blogCardAuthor}>
          <img
            src={`${U4B_IMAGE_HOST_URL}/${author.image}`}
            alt={author.name}
            className={Styles.blogCardAuthorImage}
            loading="lazy"
          />
          <div className={Styles.blogCardAuthorName}>
            <h5>{author?.name.length > 20 ? author.name.substring(0, 20) + "..." : author.name}</h5>
            {/* <p>Author</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
