/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import styles from '../../BloackedIPs/style.module.css';

// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";

import { U4B_API_HOST_URL } from "constants/envUrls";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function data() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const getUsers = async () => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(`${U4B_API_HOST_URL}/EnergyPPC/Get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then((data) => {
        if (data.isSuccess) {
          setData(data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const deleteUser = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to remove this Blocked IP?");

    if (isConfirmed) {
      fetch(`${U4B_API_HOST_URL}/EnergyPPC/Delete?id=${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.isSuccess) {
            toast.success('User deleted successfully');
            getUsers();
          }else{
            toast.error(data.message);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  // const Author = ({ fName, lName, email }) => (
  //   <MDBox display="flex" alignItems="center" lineHeight={1}>
  //     {/* <MDAvatar src={image} name={name} size="sm" /> */}
  //     <MDBox ml={2} lineHeight={1}>
  // <MDTypography display="block" variant="button" fontWeight="medium">
  //   {fName} {lName}
  // </MDTypography>
  //       <MDTypography variant="caption">{email}</MDTypography>
  //     </MDBox>
  //   </MDBox>
  // );

  const Job = ({ title }) => (
    <MDBox lineHeight={1} textAlign="left">
      {/* <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography> */}
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  // delete button
  const Actions = ({ id }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="button" fontWeight="medium">
        <button
          className={styles.blogCardButtonDelete}
          onClick={() => deleteUser(id)}
        >
          <DeleteIcon />
        </button>
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "Postal Code", accessor: "postalCode", align: "center" },
      { Header: "IP Address", accessor: "clientIPAdress", align: "center" },
      { Header: "Monthly Expenditure", accessor: "monthlyExpenditure", align: "center" },
      { Header: "Campare", accessor: "compare", align: "center" },
      { Header: "Business Type", accessor: "businessType", align: "center" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],

    rows: data
      ? data?.map((user, index) => ({
          name: <Job title={user.fullName} />,
          email: <Job title={user.email} />,
          phone: <Job title={user.phoneNumber} />,
          postalCode: <Job title={user.postalCode} />,
          clientIPAdress: <Job title={user.clientIP} />,
          monthlyExpenditure: <Job title={user.monthlyExpenditure === "empty" ? "" : user.monthlyExpenditure} />,
          compare: <Job title={user.compare === "empty" ? "" : user.compare } />,
          businessType: <Job title={user.businessType === "empty" ? "" : user.businessType} />,
          actions: <Actions id={user.id} />,
          key: index, // Assign a unique key for each row
        }))
      : [],

    data: data ? data : [],
    loading: loading,
  };
}
