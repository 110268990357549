/* eslint-disable no-bitwise */

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Link } from "react-router-dom";
import BlogsDataJson from "../../assets/static/blog-dummy.json";
import BlogCard from "./component/BlogCard";
import { useState } from "react";
import Styles from "./blogs.module.css";
import {
  ConnectivityIcon,
  ElectricityIcon,
  EnergyIcon,
  FincanceIcon,
  GasIcon,
  InsuranceIcon,
  WasteIcon,
  WaterIcon,
} from "../../assets/svgs/ServicesIcon";
import { useEffect } from "react";
import { U4B_API_HOST_URL } from "constants/envUrls";
import { CircularProgress, Pagination } from "@mui/material";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import AddBlogs from "./component/AddBlogs/addBlogs";
import EditBlogs from "./component/EditBlogs/editBlogs";


function Blogs() {
  const [activePage, setActivePage] = useState("get");
  const [controller] = useMaterialUIController();
  const [selected, setSelected] = useState({
    id: 0,
    name: "",
    activeIcon: undefined,
    inactiveIcon: undefined,
  });
  const [page, setPage] = useState(0);
  const [BlogsData, setBlogsData] = useState();
  const [editData, setEditData] = useState();
  const [paginationData, setPaginationData] = useState();
  const [loading, setLoading] = useState(false);

  const handleFilter = (service) => {
    setSelected(service);
    const filteredBlogs = BlogsDataJson.filter(
      (blog) => service.name && blog.category.includes(service.name)
    );
    setBlogsData(filteredBlogs);
  };

  const activeFill = "#EAFCE8";
  const inactiveFill = "#46A634";

  const services = [
    {
      id: 9,
      name: "Insights",
      // activeIcon: <EnergyIcon fill={activeFill} height="40" />,
      // inactiveIcon: <EnergyIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 0,
      name: "Energy",
      activeIcon: <EnergyIcon fill={activeFill} height="40" />,
      inactiveIcon: <EnergyIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 7,
      name: "Electricity",
      activeIcon: <ElectricityIcon fill={activeFill} height="40" />,
      inactiveIcon: <ElectricityIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 8,
      name: "Gas",
      activeIcon: <GasIcon fill={activeFill} height="40" />,
      inactiveIcon: <GasIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 1,
      name: "Connectivity",
      activeIcon: <ConnectivityIcon fill={activeFill} height="40" />,
      inactiveIcon: <ConnectivityIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 2,
      name: "Finance",
      activeIcon: <FincanceIcon fill={activeFill} height="40" />,
      inactiveIcon: <FincanceIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 3,
      name: "Insurance",
      activeIcon: <InsuranceIcon fill={activeFill} height="40" />,
      inactiveIcon: <InsuranceIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 4,
      name: "Water",
      activeIcon: <WaterIcon fill={activeFill} height="40" />,
      inactiveIcon: <WaterIcon fill={inactiveFill} height="40" />,
    },
    {
      id: 5,
      name: "Waste",
      activeIcon: <WasteIcon fill={activeFill} height="40" />,
      inactiveIcon: <WasteIcon fill={inactiveFill} height="40" />,
    },
  ];

  const getBlogs = async (filterBy, pageIndex, pageSize) => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(`${U4B_API_HOST_URL}/Blog/Get`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filterBy: filterBy,
        pageIndex: pageIndex,
        pageSize: pageSize,
      }),
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then((data) => {
        if (data.isSuccess) {
          if (data.data.pagedContent.length > 0) {
            setBlogsData(data.data.pagedContent);
            setPaginationData(data.data.pagingFilteringContext);
          } else {
            setBlogsData([]);
          }
          setLoading(false);
          console.log(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleChange = (value) => {
    setPage(value);
  };

  const handleEdit = (blog) => {
    setActivePage("edit");
    setEditData(blog);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this blog?");

    if(isConfirmed){
      fetch(`${U4B_API_HOST_URL}/Blog/DeleteById/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.isSuccess) {
            getBlogs(selected.id, page, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (activePage === "get") {
      getBlogs(selected.id, page, 100);
    }
  }, [selected, page, activePage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={controller.sidenavColor}
                borderRadius="lg"
                coloredShadow={controller.sidenavColor}
              >
                <MDTypography variant="h6" color="white">
                  {activePage === "edit" ? "Edit Blog" : activePage === "add" ?  "Post a Blog" : "Blogs"}
                </MDTypography>
              </MDBox>
              {/* Add new blog button */}
              {activePage === "get" && (
                <>
                  <MDBox display="flex" justifyContent="end" alignItems="center" p={3}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        setActivePage("add");
                      }}
                    >
                      <AddIcon />
                      Add New Blog
                    </MDButton>
                  </MDBox>
                  <MDBox pt={3}>
                    <div className={Styles.buttonBox}>
                      {services.map((service) => (
                        <button
                          key={service.id}
                          className={`${Styles.button} ${
                            selected.id === service.id ? Styles.activeButton : ""
                          }`}
                          onClick={() => handleFilter(service)}
                        >
                          {selected.id === service.id ? service.activeIcon : service.inactiveIcon}
                          {"    "}
                          <span>{service.name}</span>
                        </button>
                      ))}
                    </div>
                    <div className={Styles.blogsContainer}>
                      {!loading ? (
                        BlogsData?.length > 0 ? (
                          BlogsData.map((blog) => (
                            <>
                              <div
                                style={{
                                  all: "unset",
                                }}
                                // to={`/blogs/${blog.url}`}
                                key={blog.id}
                                // state={blog}
                              >
                                <BlogCard
                                  data={blog}
                                  title={blog.title}
                                  image={blog.image}
                                  date={blog.date}
                                  author={blog.author}
                                  handleEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              </div>
                            </>
                          ))
                        ) : (
                          <>
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              width="100%"
                              py={5}
                            >
                              <MDTypography
                                variant="h5"
                                textAlign="center"
                                color={controller.darkMode ? "white" : "dark"}
                              >
                                Sorry! No data found
                              </MDTypography>
                            </MDBox>
                          </>
                        )
                      ) : (
                        <MDBox display="flex" justifyContent="center" alignItems="center" py={5}>
                          <CircularProgress color="secondary" />
                        </MDBox>
                      )}
                    </div>
                  </MDBox>
                  {paginationData?.totalPages > 1 && (
                    <MDBox display="flex" justifyContent="end" alignItems="center" py={5}>
                      <Pagination
                        count={paginationData?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color="secondary"
                      />
                    </MDBox>
                  )}
                </>
              )}
              {activePage === "add" && (
                <>
                  <MDBox pt={3}>
                    <AddBlogs setActivePage={setActivePage} />
                  </MDBox>
                </>
              )}
              {activePage === "edit" && (
                <>
                  <MDBox pt={3}>
                    <EditBlogs setActivePage={setActivePage} editData={editData} />
                  </MDBox>
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Blogs;
