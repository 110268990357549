import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Paper,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles
import { U4B_API_HOST_URL } from "constants/envUrls";
import MDButton from "components/MDButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { useMaterialUIController } from "context";
import styles from "./editBlogs.module.css";

const initial = {
  title: "",
  metaTitle: "",
  metaDescription: "",
  subtitle: "",
  date: "",
  author: {
    name: "",
    image: null, // Change to null to store the file
  },
  image: null, // Change to null to store the file
  imageAlt: "",
  category: 0,
  content: {
    html: "",
  },
  url: "",
};

const EditBlogs = ({ setActivePage, editData }) => {
  const initialValues = {
    title: editData.title,
    metaTitle: editData.metaTitle,
    metaDescription: editData.metaDescription,
    subtitle: editData.subtitle,
    date: "",
    author: {
      name: editData.author.name,
      image: null, // Change to null to store the file
    },
    image: null, // Change to null to store the file
    imageAlt: editData.altText,
    category: editData.category,
    content: {
      html: editData.content.html,
    },
    url: editData.url,
  };
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleCategoryChange = (e) => {
    setValues({ ...values, category: e.target.value });
  };

  const handleAuthorImageChange = (e) => {
    const file = e.target.files[0];
    setValues({ ...values, author: { ...values.author, image: file } });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setValues({ ...values, image: file });
  };

  const handleContentImageChange = (e) => {
    const file = e.target.files[0];
    setValues({ ...values, content: { ...values.content, image: file } });
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!values.title) {
      newErrors.title = "Title is required";
    }
    if (!values.metaTitle) {
      newErrors.metaTitle = "Meta Title is required";
    }
    if (!values.metaDescription) {
      newErrors.metaDescription = "Meta Description is required";
    }
    if (!values.subtitle) {
      newErrors.subtitle = "Subtitle is required";
    }

    if (!values.author.name) {
      newErrors.authorName = "Author Name is required";
    }

    if (!values.url) {
      newErrors.url = "URL is required";
    }

    if (!values.content.html) {
      newErrors.contentHtml = "Blog description is required";
    }

    if (Object.keys(newErrors).length === 0) {
      postABlog();
    } else {
      setErrors(newErrors);
    }
  };

  const handleContentChange = (html) => {
    setValues({
      ...values,
      content: {
        html,
      },
    });
  };

  const postABlog = async () => {
    // handleSubmit()
    if (!submitted) {
      setLoading(true);
      const formData = new FormData();
      // Append your blog data to the FormData object
      formData.append("id", editData.id);
      formData.append("title", values.title);
      formData.append("subtitle", values.subtitle);
      formData.append("metaTitle", values.metaTitle);
      formData.append("metaDescription", values.metaDescription);
      formData.append("Author.name", values.author.name);
      formData.append("Author.AuthorImage", values.author.image); // authorImageFile should be the File object
      formData.append("BlogImage", values.image); // blogImageFile should be the File object
      formData.append("category", values.category);
      formData.append("AltText", values.imageAlt);
      formData.append("content.html", values.content.html);
      formData.append("url", values.url);
      // eslint-disable-next-line no-unused-vars
      try {
        const response = await fetch(`${U4B_API_HOST_URL}/Blog/Update`, {
          method: "POST",
          body: formData, // Use the FormData object as the request body
        });

        if (response.ok) {
          const data = await response.json();
          if (data.isSuccess) {
            setSubmitted(false);
            setLoading(false);
            setValues(initial);
            toast.success(data.message);
            setActivePage("get");
          } else {
            setLoading(false);
            toast.warning(data.message);
          }
        } else {
          // Handle any errors here
          toast.error("Something went wrong");
          setLoading(false);
        }
      } catch (err) {
        // Handle network errors
        console.log(err);
        setLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     title : editData.title,
  //     subtitle : editData.subtitle,
  //     metaTitle : editData.metaTitle,
  //     metaDescription : editData.metaDescription,
  //     author : {
  //       name : editData.author.name,
  //       image : null
  //     },
  //     content : {
  //       html : editData.content.html,
  //     },
  //     category : editData.category,
  //     url : editData.url,
  //     image : null,
  //   })
  // }, [editData])

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6] }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "code-block",
  ];

  return (
    <>
      <div>
        <div style={{ padding: "20px" }}>
          <MDBox display="flex" justifyContent="end" alignItems="center" p={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setActivePage("get");
              }}
            >
              <ArrowBackIcon />
              Back
            </MDButton>
          </MDBox>
          {/* <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" py={2}>
            <Typography variant="h3">Post a Blog</Typography>
          </MDBox> */}

          <form className={darkMode ? styles.lightForm : styles.darkForm}>
            <Grid container spacing={2}>
              <MDBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
                p={2}
              >
                <Typography variant="h4">Blog details</Typography>
              </MDBox>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="title"
                  label="Title"
                  variant="filled"
                  value={values.title}
                  onChange={handleInputChange}
                  fullWidth
                  error={Boolean(errors.title)}
                  helperText={errors.title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="subtitle"
                  label="Subtitle"
                  variant="filled"
                  value={values.subtitle}
                  onChange={handleInputChange}
                  fullWidth
                  error={Boolean(errors.subtitle)}
                  helperText={errors.subtitle}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="Blog Image"
                  // label="Blog Image"
                  variant="filled"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  error={Boolean(errors.image)}
                  fullWidth
                />
                {/* <input  /> */}
                {errors.image && (
                  <Typography variant="body2" color="error">
                    {errors.image}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="imageAlt"
                  label="Image Alt Text"
                  variant="filled"
                  value={values.imageAlt}
                  onChange={handleInputChange}
                  fullWidth
                  // error={Boolean(errors.imageAlt)}
                  // helperText={errors.imageAlt}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  name="category"
                  value={values.category}
                  onChange={handleCategoryChange}
                  label="Category"
                  error={Boolean(errors.category)}
                  SelectProps={{
                    native: true,
                  }}
                  variant="filled"
                  fullWidth
                >
                  <option key={9} value={9}>
                    Insights
                  </option>
                  <option key={0} value={0}>
                    Energy
                  </option>
                  <option key={7} value={7}>
                    Electricity
                  </option>
                  <option key={8} value={8}>
                    Gas
                  </option>
                  <option key={1} value={1}>
                    Connectivity
                  </option>
                  <option key={2} value={2}>
                    Finance
                  </option>
                  <option key={3} value={3}>
                    Insurance
                  </option>
                  <option key={4} value={4}>
                    Water
                  </option>
                  <option key={5} value={5}>
                    Waste
                  </option>
                </TextField>
                <Typography variant="body2" color="error">
                  {errors.category}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  value={values.content.html}
                  onChange={handleContentChange}
                  modules={modules}
                  formats={formats}
                  style={{
                    height: "300px",
                    marginBottom: "80px",
                    color: darkMode ? "#fff" : "#000",
                  }} // Adjust the height here
                />
                <Typography variant="body2" color="error">
                  {errors.contentHtml}
                </Typography>
              </Grid>

              <MDBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
                p={2}
              >
                <Typography variant="h4">Author details</Typography>
              </MDBox>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="authorName"
                  label="Author Name"
                  variant="filled"
                  value={values.author.name}
                  onChange={(e) => {
                    setValues({ ...values, author: { ...values.author, name: e.target.value } });
                  }}
                  fullWidth
                  error={Boolean(errors.authorName)}
                  helperText={errors.authorName}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="Author Image"
                  // label="Blog Image"
                  variant="filled"
                  type="file"
                  accept="image/*"
                  onChange={handleAuthorImageChange}
                  error={Boolean(errors.authorImage)}
                  fullWidth
                />
                {/* <input type="file" accept="image/*" onChange={} /> */}
                {errors.authorImage && (
                  <Typography variant="body2" color="error">
                    {errors.authorImage}
                  </Typography>
                )}
              </Grid>

              <MDBox
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
                p={2}
              >
                <Typography variant="h4">SEO details</Typography>
              </MDBox>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="metaTitle"
                  label="Meta Title"
                  variant="filled"
                  value={values.metaTitle}
                  onChange={handleInputChange}
                  fullWidth
                  error={Boolean(errors.metaTitle)}
                  helperText={errors.metaTitle}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="metaDescription"
                  label="Meta Description"
                  variant="filled"
                  value={values.metaDescription}
                  onChange={handleInputChange}
                  fullWidth
                  error={Boolean(errors.metaDescription)}
                  helperText={errors.metaDescription}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="url"
                  label="URL"
                  variant="filled"
                  value={values.url}
                  onChange={handleInputChange}
                  fullWidth
                  error={Boolean(errors.url)}
                  helperText={errors.url}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width="100%"
                  p={2}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {!loading ? "Edit" : <CircularProgress color="inherit" size={20} />}
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBlogs;
