/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { CircularProgress } from "@mui/material";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { TrafficOutlined } from "@mui/icons-material";
import { U4B_API_HOST_URL } from "constants/envUrls";
import MDAlert from "components/MDAlert";
import { AuthContext } from "context/Auth";

function Basic() {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const alertContent = (message) => (
    <MDTypography variant="body2" color="white">
      {message}
    </MDTypography>
  );

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setError("");
  };

  const handleEmailBlur = () => {
    if (!email) {
      setError("*User Name is required.");
    }
  };

  const handlePaswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setErrorPassword("");
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setErrorPassword("*Password is required.");
    }
  };

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async () => {
    setApiMessage("");
    setLoading(true);
    try {
      const response = await fetch(`${U4B_API_HOST_URL}/account/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      
      if (response.ok) {
        if(data.isSuccess){
          setApiMessage("");
          login(data.message, data.expireDate);
          setLoading(false);
        }else{
          setApiMessage(data.message);
          setLoading(false);
        }
      } else {
        const errorData = await response.json();
        setLoading(false);
        setApiMessage(errorData.message);
        console.log(errorData);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="User Name"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={Boolean(error)}
                helperText={<span style={{ color: "#F44335" }}>{error}</span>}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={handlePaswordChange}
                onBlur={handlePasswordBlur}
                error={Boolean(errorPassword)}
                helperText={<span style={{ color: "#F44335" }}>{errorPassword}</span>}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleLogin()}
                disabled={Boolean(error) || Boolean(errorPassword) || loading}
              >
                {loading ? <CircularProgress color="inherit" size={19} /> : "sign in"}
              </MDButton>
            </MDBox>

            {Boolean(apiMessage) && (
              <MDBox mt={3} mb={1} textAlign="center">
                <MDAlert color="error" dismissible position="absolute">
                  {alertContent(apiMessage)}
                </MDAlert>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
