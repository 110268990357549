/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";

import { U4B_API_HOST_URL } from "constants/envUrls";
import { useEffect, useState } from "react";

export default function data() {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const getUsers = async () => {
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    const response = await fetch(`${U4B_API_HOST_URL}/Contact/GetContacts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then((data) => {
        if (data.isSuccess) {
          setData(data.data);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);


  const Job = ({ title , width }) => (
    <MDBox lineHeight={1} textAlign="left" width={width}> 
      <MDTypography display="block" variant="button" fontWeight="medium">
        {title}
      </MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "IP Address", accessor: "clientIPAddress", align: "center" },
      { Header: "Source", accessor: "source", align: "center" },
      { Header: "Message", accessor: "message", align: "center" },
    ],

    rows: data
      ? data?.map((user, index) => ({
          name: <Job title={user.fullName} />,
          email: <Job title={user.email} />,
          phone: <Job title={user.phoneNumber} />,
          clientIPAddress: <Job title={user.clientIPAddress} />,
          source: <Job title={user.source} />,
          message: <Job title={user.message.length > 200 ? user.message.substring(0, 200) + "..." : user.message}  width={600}/>,
          key: index,
        }))
      : [],

    data: data ? data : [],
    loading: loading,
  };
}
