import React, { createContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is already logged in based on local storage details
    const userInfo = localStorage.getItem("UserInfo");
    if (userInfo) {
      const { token, expiryDate } = JSON.parse(userInfo);
      if (token && expiryDate && new Date(expiryDate) > new Date()) {
        setIsAuthenticated(true);
      }
    }
  }, []);

  const login = (token, expiryDate) => {
    // Save token and expiry date in local storage
    localStorage.setItem("UserInfo", JSON.stringify({ token, expiryDate }));
    setIsAuthenticated(true);
  };

  const logout = () => {
    // Remove user details from local storage
    localStorage.removeItem("UserInfo");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
