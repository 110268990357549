import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { CSVLink } from "react-csv";

export default function AlertDialog({ open, handleCloseModel, selectedRow, controller }) {
  //   const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    handleCloseModel();
  };

  const handleDate = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    return localDate.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',  
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        PaperProps={{
          style: {
            backgroundColor: controller.darkMode ? "#1A2035" : "#ffffff",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h3"> Lead details</Typography>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                {selectedRow.firstName && (
                  <CSVLink
                    data={[selectedRow]}
                    filename={selectedRow.firstName + " " + selectedRow.lastName + "_details"}
                  >
                    <Button>
                      {" "}
                      <DownloadIcon
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Button>
                  </CSVLink>
                )}
                {selectedRow.fullName && (
                  <CSVLink data={[selectedRow]} filename={selectedRow.fullName + "_details"}>
                    <Button>
                      {" "}
                      <DownloadIcon
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Button>
                  </CSVLink>
                )}
                {selectedRow.name && (
                  <CSVLink data={[selectedRow]} filename={selectedRow.name + "_details"}>
                    <Button>
                      {" "}
                      <DownloadIcon
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </Button>
                  </CSVLink>
                )}
              </DialogActions>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {selectedRow.firstName && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {selectedRow.firstName + " " + selectedRow.lastName}
                  </Typography>
                </Grid>
              </>
            )}
            {selectedRow.fullName && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.fullName}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.name && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.name}</Typography>
                </Grid>
              </>
            )}

            {selectedRow.email && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Email:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.email}</Typography>
                </Grid>
              </>
            )}

            {selectedRow.phoneNumber && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Phone Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.phoneNumber}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.landlineNumber && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Landline Number:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.landlineNumber}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.createdDate && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {handleDate(selectedRow.createdDate)}
                  </Typography>
                </Grid>
              </>
            )}
            {selectedRow.postalCode && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Postal Code:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.postalCode || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.postCode && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Postal Code:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.postCode || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.address && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Address:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.address || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.companyName && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Company Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.companyName || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.businessName && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Business Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.businessName || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.compare && selectedRow.compare !== "empty" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Help required for:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.compare || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.supplier && selectedRow.supplier !== "empty" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Supplier:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.supplier || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.query && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Services to Compare:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.query || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.budget && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Annual Expenditure Range:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.budget || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.primaryEnergyConcern && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Primary Energy Concern:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.primaryEnergyConcern || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.noOfEmployees && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">No. of Employees:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.noOfEmployees || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.agreeTerms && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Agree Terms:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.agreeTerms ? "Yes" : "No"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.newBusiness && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">New Business:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.newBusiness ? "Yes" : "No"}</Typography>
                </Grid>
              </>
            )}

            {selectedRow.utility && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Utility:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.utility}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.currentSupplier && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Current Supplier:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.currentSupplier}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.contractEndDate && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Contract End Date:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.contractEndDate}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.source && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Source:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.source}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.reason && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Source:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.reason}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.clientIPAddress && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">User IP:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.clientIPAddress}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.clientIPAdress && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">User IP:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.clientIPAdress}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.clientIP && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">User IP:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.clientIP}</Typography>
                </Grid>
              </>
            )}

            {selectedRow.message && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Message:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.message || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.monthlyExpenditure && selectedRow.monthlyExpenditure !== "empty" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Monthly Expenditure:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.monthlyExpenditure || "N/A"}</Typography>
                </Grid>
              </>
            )}
            {selectedRow.businessType && selectedRow.businessType !== "empty" && (
              <>
                <Grid item xs={6}>
                  <Typography variant="h5">Business Type:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{selectedRow.businessType || "N/A"}</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
